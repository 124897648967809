import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { PrivacyTemplate } from '../templates/privacy-template'

const PrivacyPage = ({data}) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <PrivacyTemplate
            privacy = { frontmatter }
        />
    )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default PrivacyPage

export const pageQuery = graphql`
query PrivacyTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" }}) {
        frontmatter {
            text
        }
    }
}
`