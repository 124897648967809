import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import Spacer from '../components/Spacer'

const TextContainer = styled.div`
width:90%;
max-width:900px;
margin:auto;

& h1{
    font-size:24px;
    font-family:Open Sans;
    font-weight:600;
}

& h2, h3{
    position:relative;
    font-size:20px;
    font-family:Open Sans;
    font-weight:400;
    margin-top:30px;
}

& p{
    font-size:16px;
}

@media(min-width:983px){
    & h1{
        font-size:36px;
    }

    & h2, h3{
        font-size:26px;
        margin-top:40px;
    }

    & p{
        font-size:18px;
    }
}
`

export const PrivacyTemplate = ({
    privacy
}) => (
    <div>
        <Helmet defer={false}>
            <title>Datenschutz</title>
            <meta name="robots" content="noindex"/>
        </Helmet>
        <Layout>
            <Spacer height={150}/>
            <TextContainer>
                <ReactMarkdown>{privacy.text}</ReactMarkdown>
            </TextContainer>
            <Spacer height={150}/>
        </Layout>
    </div>
)

PrivacyTemplate.propTypes = {
}